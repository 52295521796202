import classnames from "classnames";
import React from "react";

import { useTheme } from "foundation/Theme";
import { Themes, themeStyleName } from "styles";

import styles from "./Page.module.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  backgroundColor?: Themes;
  fullHeight?: boolean;
  soloPage?: boolean;
  justifyContent?: "center" | "flex-start" | "flex-end";
  alignItems?: "center" | "flex-start" | "flex-end";
  backgroundImage?: string;
  borders?: {
    top?: boolean;
    bottom?: boolean;
  };
}

export const Page = React.forwardRef(
  (
    {
      children,
      borders,
      soloPage,
      justifyContent,
      alignItems,
      backgroundColor,
      className,
      backgroundImage,
      fullHeight = false,
    }: Props,
    ref: any
  ) => {
    const contextTheme = useTheme();
    const style = {} as { [key: string]: string | undefined };

    if (justifyContent || alignItems) {
      style["display"] = "flex";
      style["justifyContent"] = justifyContent;
      style["alignItems"] = alignItems;
    }

    const theme = backgroundColor || contextTheme;

    return (
      <div
        className={classnames(className, styles.Page, themeStyleName(theme, "Page"), {
          [styles.FullHeight]: fullHeight,
          [styles.BorderTop]: borders?.top,
          [styles.BorderBottom]: borders?.bottom,
          [styles.SoloPage]: soloPage,
        })}
        style={style}
        ref={ref}
      >
        {backgroundImage && (
          <div
            className={styles.BackgroundImage}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          />
        )}
        {children}
      </div>
    );
  }
);

Page.displayName = "Page";
