import querystring from "querystring";

function orderSuccessRoute(id: string) {
  return `/order/success/${id}`;
}

function orderReceivedRoute(id: string) {
  return `/order/received/${id}`;
}

function productRoute(id: number) {
  return `/product/${id}`;
}

function errorRoute(message?: string) {
  return `/error?${querystring.stringify({ message })}`;
}

function homeRoute() {
  return "/";
}

function loginPageRoute() {
  return "/login";
}

function logoutPageRoute() {
  return "/logout";
}

function registerPageRoute() {
  return "/register";
}

function loginRoute() {
  return "/api/login";
}

function registerRoute() {
  return "/api/register";
}

function coursesRoute() {
  return "/courses";
}

export {
  loginPageRoute,
  logoutPageRoute,
  registerPageRoute,
  orderSuccessRoute,
  orderReceivedRoute,
  errorRoute,
  homeRoute,
  loginRoute,
  registerRoute,
  productRoute,
  coursesRoute,
};
