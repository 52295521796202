import { StaticImageData } from "next/image";

import { BusinessMeetingImage, KidsSeminarImage } from "assets";

export const PRODUCT_IMAGES: { [key: string]: StaticImageData } = {
  "business-seminar.png": BusinessMeetingImage,
  "kids-seminar.png": KidsSeminarImage,
};

export const USER_ID_HEADER = "X-Header-User-Local-Id";

export const HEADER_HEIGHT = 0;
