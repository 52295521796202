import Image from "next/image";

import { HeroMain } from "assets";
import { Logo } from "foundation/Logo";
import { ThemeProvider } from "foundation/Theme";

import styles from "./HeroWithImage.module.scss";

export function HeroWithImage() {
  return (
    <ThemeProvider theme="sand">
      <div className={styles.Container}>
        <div className={styles.WhiteOverlay} />

        <div className={styles.Content}>
          <div className={styles.LeftSide}>
            <Image className={styles.HeroImage} src={HeroMain} alt="Background" priority/>
          </div>
          <div className={styles.RightSide}>
            <Logo type="wordmarkSubtext" size="headline" />
            <div className={styles.Headline}>
              <span>Etiketas - sėkmės kalba,</span>
              <span> kuria mokau kalbėti!</span>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
