import React, { useMemo } from "react";

import { CreamSilkBackground, VelvetBackground } from "assets";
import { FeaturedProducts } from "components/FeaturedProducts";
import { Footer } from "components/Footer";
import { HeroWithImage } from "components/HeroWithImage/HeroWithImage";
import { useNavigation } from "foundation/NavigationProvider";
import { ThemeProvider } from "foundation/Theme";
import { Themes } from "styles";
import { CornerImageTextPage } from "components/CornerImageTextPage";

import { AboutEttiquete } from "../AboutEttiquete";
import { ContactUs } from "../ContactUs";
import { Page } from "../Page";
import { ItemType, SideMenu } from "../SideMenu";

import styles from "./Home.module.scss";

interface PageType {
  id: string;
  label: string;
  component: React.FC;
  theme: Themes;
  fullHeight?: boolean;
  excludeFromMenu?: boolean;
  backgroundImage?: string;
}

const pages = [
  {
    id: "Hero",
    label: "Pagrindinis",
    component: HeroWithImage,
    theme: "cream",
    fullHeight: true,
    excludeFromMenu: true,
    backgroundImage: CreamSilkBackground.src
  },
  {
    id: "Courses",
    label: "Kursai ir pamokos",
    component: FeaturedProducts,
    payloadKey: "courses",
    theme: "white",
    backgroundImage: VelvetBackground.src
  },
  {
    id: "About Me",
    label: "Apie mane",
    component: CornerImageTextPage,
    theme: "cream",
  },
  {
    id: "AboutEttiquete",
    label: "Kas yra etiketas?",
    component: AboutEttiquete,
    theme: "darkGreen",
  },
  {
    id: "ContactUs",
    label: "Susisiekite",
    component: ContactUs,
    theme: "cream",
  },
] as PageType[];

function Home() {
  const { activePage, goToPage, registerPageRef } = useNavigation();

  const handlePageChange = (pageId: string) => () => {
    goToPage(pageId);
  };

  const menuItems = pages
    .map(({ id, label, excludeFromMenu }) => {
      if (excludeFromMenu) {
        return;
      }

      return {
        id,
        label,
        onClick: handlePageChange(id),
        active: activePage === id,
      };
    })
    .filter(Boolean) as ItemType[];

  const pagesComponents = useMemo(() => {
    return pages.map(({ id, theme, fullHeight, component: Component, backgroundImage }, index) => {
      const ref = React.createRef<HTMLDivElement>();
      registerPageRef({ field: id, value: ref });

      return (
        <ThemeProvider key={id} theme={theme}>
          <Page
            borders={{ bottom: index !== pages.length - 1 }}
            fullHeight={fullHeight}
            ref={ref}
            backgroundImage={backgroundImage}
          >
            <Component />
          </Page>
        </ThemeProvider>
      );
    });
  }, [registerPageRef]);

  return (
    // <StaticPageProvider initialValue={<LandingPage />}>
    <div className={styles.Container}>
      <ThemeProvider theme="cream">
        <SideMenu items={menuItems} />
      </ThemeProvider>
      {pagesComponents}
      <ThemeProvider theme="cream">
        <Footer />
      </ThemeProvider>
    </div>
    // </StaticPageProvider>
  );
}

export default Home;
