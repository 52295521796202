import React from "react";
import classNames from "classnames";

import { ThemesStyles } from "styles";
import { useTheme } from "foundation/Theme";

import styles from "./Box.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export function Box({ children, className }: Props) {
  const theme = useTheme();

  return (
    <div className={classNames(ThemesStyles[`Theme_${theme}--Box`], styles.Container, className)}>
      <div className={classNames(styles.BackgroundOverlay, `Theme_${theme}--Box__overlay`)}></div>
      {children}
    </div>
  );
}
