export const aboutMe = `<p>Esu etiketo ekspertė ir lektorė, turinti ilgametę patirtį, kaip padėti
žmonėms suvokti socialinių normų ir elgesio taisyklių svarbą. Mano
aistra etiketo mokymui kyla iš noro padėti kitiems jaustis užtikrintai
įvairiose situacijose – nuo verslo susitikimų iki socialinių renginių.
Kiekvienas seminaras ir mokymai, kuriuos vedu, yra orientuoti į
praktinius įgūdžius ir žinias, kad dalyviai galėtų sėkmingai taikyti
etiketo principus savo kasdieniame gyvenime.</p>
<p>Mano požiūris į etiketo mokymą yra įkvėptas moteriškumo, subtilumo
ir elegancijos. Aš tikiu, kad tikrasis stilius ir elegancija kyla iš vidinės
harmonijos ir pasitikėjimo savimi. Todėl savo mokymuose akcentuoju
ne tik išorinius aspektus, bet ir vidinę kultūrą, kuri padeda formuoti
išskirtinį asmeninį įvaizdį. Moteriškumas man yra neatsiejama dalis
etiketo, ir stengiuosi įkvėpti moteris būti savimi, išlaikant eleganciją ir
rafinuotumą. </p>
<p>Mano tikslas – suteikti ne tik teorinių žinių, bet ir praktinių įgūdžių,
kurie padėtų kiekvienai moteriai atrasti savo unikalų stilių ir
pasitikėjimą. Esu įsitikinusi, kad kiekviena moteris gali tapti elegantiška
ir subtilia savo gyvenimo heroine, ir džiaugiuosi galėdama būti šio
proceso dalimi. Kiekvienas mano seminaras yra ne tik mokymosi
patirtis, bet ir galimybė susipažinti su naujais žmonėmis, dalintis
idėjomis ir kurti bendruomenę, kurioje etiketą ir stilių vertiname kaip
svarbius gyvenimo aspektus.</p>`;