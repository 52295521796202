import Link from "next/link";

import { Button } from "components/Button";
import { ContactModal } from "components/Courses/components/ContactModal";
import { useModal } from "components/Modal";
import { Box } from "foundation/Box";
import { Product, ProductType } from "types/schema";

import styles from "./FeaturedProduct.module.scss";

type ActionElement = ({ product }: { product: Product }) => JSX.Element;

interface FeaturedProductProps {
  product: Product;
}

const DefaultAction: ActionElement = ({ product }) => {
  return (
    <Link href={`product/${product.id}`}>
      <Button responsive>Užsiregistruoti</Button>
    </Link>
  );
};

const SeminarActions: ActionElement = ({ product }) => {
  const { show } = useModal();

  const modal = <ContactModal topic={product.title} />;

  const onClick = () => {
    show(modal);
  };

  return (
    <>
      <Button priority="primary" outlined responsive onClick={onClick} size="small">
        Susisiekti
      </Button>
      <Link href={`product/${product.id}`}>
        <Button responsive size="small" priority="secondary" outlined>Detaliau</Button>
      </Link>
    </>
  );
};

const actions: { [key in ProductType]?: ActionElement } = {
  [ProductType.SEMINAR]: SeminarActions,
};

export const FeaturedProduct = ({ product }: FeaturedProductProps) => {
  const Actions = actions[product.type] || DefaultAction;

  return (
    <Box className={styles.Product}>
      <div className={styles.ProductTitle}>
        <h2>{product.title}</h2>
      </div>
      <div className={styles.ProductDivider} />
      <div className={styles.ProductDescription}>{product.description}</div>
      <div className={styles.ProductFooter}>
        <div className={styles.ProductDivider} />
        <div className={styles.ProductActions}>
          <Actions product={product} />
        </div>
      </div>
    </Box>
  );
};
