import classNames from "classnames";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";

import { Button } from "components/Button";
import { Text } from "foundation/Text";
import { useUser } from "foundation/UserProvider/UserProvider";
import { coursesRoute, homeRoute, logoutPageRoute } from "src/routes";

import { Hamburger } from "../Hambuger";

import { Item } from "./Item";
import styles from "./SideMenu.module.scss";

export interface ItemType {
  id: string;
  label: string;
  onClick: () => void;
  active?: boolean;
}

interface Props {
  items?: ItemType[];
}

export function SideMenu({ items = [] }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const router = useRouter();
  const user = useUser();
  let lastScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsHeaderHidden(true);
      } else {
        setIsHeaderHidden(false);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onItemClicked = (onClick: () => void) => () => {
    onClick();
    toggleMenu();
  };

  const onLogoutClicked = () => {
    router.push(logoutPageRoute());
  };

  const homeItem = router.pathname !== "/" && (
    <Item onClick={onItemClicked(() => router.push(homeRoute()))}>
      Pagrindinis
    </Item>
  );

  const userMenuItems = user && [
    <Item key="profile" onClick={onItemClicked(() => console.log("profile"))}>
      Profilis
    </Item>,
    <Item key="courses" onClick={onItemClicked(() => router.push(coursesRoute()))}>
      Mano kursai
    </Item>,
    <Item key="logout" onClick={onItemClicked(onLogoutClicked)}>
      Atsijungti
    </Item>,
    <div className={styles.Divider} key="divider" />,
  ];

  const menuItems = items.map((item) => {
    return (
      <Item key={item.id} onClick={onItemClicked(item.onClick)} active={item.active}>
        {item.label}
      </Item>
    );
  });

  const userInfoMini = user && (
    <div className={styles.UserInfo}>
      <Text>Sveiki,&nbsp;</Text>
      <Text type="accent">
        {user.firstName} {user.lastName}
      </Text>
    </div>
  );

  const loginButton = !user && (
    <>
      <Button className={styles.LoginButton} priority="secondary" onClick={() => router.push("/login")}>
        Prisijungti
      </Button>
      <Button className={styles.RegisterButton} priority="secondary" onClick={() => router.push("/register")}>
        Registruotis
      </Button>
    </>
  );

  return (
      <div className={styles.Container}>
        <div className={classNames(styles.Header, { [styles.HeaderHidden]: isHeaderHidden })}>
          {userInfoMini}
          {loginButton}
          <Hamburger className={styles.Hamburger} onClick={toggleMenu} open={isOpen} />
        </div>

        <div className={classNames(styles.Menu, { [styles.MenuOpen]: isOpen })}>
          {homeItem}
          {userMenuItems}
          {menuItems}
        </div>
      </div>
  );
}
