import classNames from "classnames";

import { IconLogo, WordmarkLogo, WordmarkSubtextLogo } from "assets";
import { useTheme } from "foundation/Theme";
import { Themes, themeStyleName } from "styles";

import styles from "./Logo.module.scss";

type LogoType = "wordmark" | "icon" | "wordmarkSubtext";
type LogoSize = "extra-small" | "small" | "medium" | "large" | "extra-large" | "headline";
interface Props {
  type?: LogoType;
  size?: LogoSize;
  theme?: Themes;
  responsive?: boolean;
}

const LOGO_TYPES: { [key in LogoType]: any } = {
  wordmark: WordmarkLogo,
  icon: IconLogo,
  wordmarkSubtext: WordmarkSubtextLogo,
};

export function Logo({ type = "wordmark", theme, size = "medium", responsive }: Props) {
  const contextTheme = useTheme();
  
  const LogoComponent = LOGO_TYPES[type];

  if (!LogoComponent) {
    return null;
  }

  const themeToUse = theme || contextTheme;

  return (
    <div
      className={classNames(styles.Logo, styles[`Size__${size}`], themeStyleName(themeToUse, "Logo"), {
        [styles.Responsive]: responsive,
      })}
    >
      <LogoComponent />
    </div>
  );
}
