import classNames from "classnames";
import Image from "next/image";

import { MeCreamCoat, SilkCreamFolds } from "assets";

import styles from "./CornerImageTextPage.module.scss";
import { aboutMe } from "./data";

export const CornerImageTextPage = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.WhiteOverlay} />
      <div
        className={styles.BackgroundImage}
        style={{
          backgroundImage: `url(${SilkCreamFolds.src})`,
        }}
      />
      <div className={classNames(styles.Line, styles.Line__bottom)} />
      <div className={classNames(styles.Line, styles.Line__left)} />
      <div className={styles.Content}>
        <Image className={styles.Image} src={MeCreamCoat} alt="SilkCreamFolds" />
        <div className={styles.Text} dangerouslySetInnerHTML={{ __html: aboutMe }} />
      </div>
    </div>
  );
};
