import classNames from "classnames";
import React from "react";

import { useTheme } from "foundation/Theme";
import { Themes, themeStyleName } from "styles";

import styles from "./Button.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: Themes;
  align?: "left" | "right" | "center";
  responsive?: boolean;
  minWidth?: number;
  priority?: "primary" | "secondary" | "default";
  size?: "small" | "medium" | "large";
  outlined?: boolean;
}

const PRIORITY_STYLE = {
  primary: "Button__primary",
  secondary: "Button__secondary",
  default: "Button",
};

export function Button({
  children,
  responsive,
  minWidth,
  theme: customTheme,
  className,
  align = "center",
  priority,
  outlined,
  ...rest
}: Props) {
  const themePriority = PRIORITY_STYLE[priority ?? "default"];

  const themeContextValue = useTheme();
  const theme = customTheme ?? themeContextValue;

  return (
    <button
      style={{
        minWidth: minWidth ?? 0,
      }}
      className={classNames(
        themeStyleName(theme, themePriority),
        styles.Button,
        styles[`Align__${align}`],
        styles[`Size__${rest.size ?? "medium"}`],
        className,
        {
          [styles.Responsive]: responsive,
          [styles.Disabled]: rest.disabled,
          [styles.Outlined]: outlined
        }
      )}
      {...rest}
    >
      {children}
    </button>
  );
}
