
import { useProducts } from "src/context";

import { FeaturedProduct } from "./components/FeaturedProduct";
import styles from "./FeaturedProducts.module.scss";

export const FeaturedProducts = () => {
  const products = useProducts();

  if (!products || products.length === 0) {
    return null;
  }
  
  return (
    <div className={styles.Container}>
      <div className={styles.Title}>
        <h1>Etiketo mokymai</h1>
      </div>
      <div className={styles.Products}>
        {products.map((product, index) => (
          <FeaturedProduct key={index} product={product} />
        ))}
      </div>
    </div>
  );
};
